import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/web2/gatsby-starter-blog/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div className="col-lg-8">
      <h2>{`Business Development`}</h2>
      <p>{`I helped this Korean window tinting franchise to prepare their plans to internationalize from scratch. Starting with researching possible target markets, preparing the necessary documentation and then being the liason for all foreign customers. `}</p>
      <p>{`Although their brand is locally strong, all digital content was highly focused in Naver (Korea's main search engine) and lacking presence outside of Korea.  `}</p>
      <p>{`With the help of the graphic designer we prepared all marketing and promotional material, redesigned the foreign website to explain the different product families, and I copy wrote all their content to help it position better in Google.`}</p>
      <p>{`The result was seen over a few months: business opportunities grew and led to the openings of shops in Colombia and Saudi Arabia. `}</p>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-6">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/fcd8900f487794dbf6d97f0063c84c16/bcec7/glasstint-03.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAQEBAQAAAAAAAAAAAAAAAAIDBAX/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAB7anKXleiGdBLFH//xAAaEAADAQADAAAAAAAAAAAAAAAAARECEBMj/9oACAEBAAEFAlg8ppV6ZU31opeP/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/ASv/xAAXEQEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAECAQE/AWIr/8QAHBAAAQQDAQAAAAAAAAAAAAAAAAECMYEgIkFR/9oACAEBAAY/AtkQ4eELRLrbj//EAB0QAQACAgIDAAAAAAAAAAAAAAEAESFBMVFhcZH/2gAIAQEAAT8hycHSTK2XqIqNOozReO9YBCS4CT5fsFFlS5//2gAMAwEAAgADAAAAEJvggP/EABkRAAIDAQAAAAAAAAAAAAAAAAABEBEhUf/aAAgBAwEBPxDUV4OP/8QAGhEAAAcAAAAAAAAAAAAAAAAAAAEQESFBUf/aAAgBAgEBPxCDoP1X/8QAHhABAAICAgMBAAAAAAAAAAAAAQARITFBUWGRwYH/2gAIAQEAAT8QMzgORfinJ7mWaautn8nWBCaOodoWtSb3364iRDAT85+xbYA6EEsXcol2UOYJn//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Glasstint",
                "title": "Glasstint",
                "src": "/static/fcd8900f487794dbf6d97f0063c84c16/4b190/glasstint-03.jpg",
                "srcSet": ["/static/fcd8900f487794dbf6d97f0063c84c16/e07e9/glasstint-03.jpg 200w", "/static/fcd8900f487794dbf6d97f0063c84c16/066f9/glasstint-03.jpg 400w", "/static/fcd8900f487794dbf6d97f0063c84c16/4b190/glasstint-03.jpg 800w", "/static/fcd8900f487794dbf6d97f0063c84c16/bcec7/glasstint-03.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/1281143d695c0e20a9b10a095bb00363/bcec7/glasstint-04.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBQH/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAgH/2gAMAwEAAhADEAAAAdKvufM7ittWRCQP/8QAGxAAAgIDAQAAAAAAAAAAAAAAAQIAAwQQEhH/2gAIAQEAAQUCsuFZTIVjMirtQtvSAqkA81//xAAVEQEBAAAAAAAAAAAAAAAAAAAgIf/aAAgBAwEBPwGj/8QAFREBAQAAAAAAAAAAAAAAAAAAICH/2gAIAQIBAT8Bo//EAB0QAQABBAMBAAAAAAAAAAAAAAERAAIxcRAgIoH/2gAIAQEABj8CCF1UejfE4uKgtPtA56f/xAAdEAEAAAYDAAAAAAAAAAAAAAABABARITFBYYGR/9oACAEBAAE/IWoh8Rmq42kFI6DC7RdphwxBdJDjXtl//9oADAMBAAIAAwAAABBUJ4L/xAAXEQEAAwAAAAAAAAAAAAAAAAABESAh/9oACAEDAQE/EFllP//EABYRAQEBAAAAAAAAAAAAAAAAAAEgMf/aAAgBAgEBPxBRkf/EAB8QAQACAQQDAQAAAAAAAAAAAAEAEUEhMVGBEHGR8P/aAAgBAQABPxDhT6vKr+RsxGzT9Y7hNl4aLqDdNYmecFEfupQZUCh9EQSnZiypXyPj/9k=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Glasstint",
                "title": "Glasstint",
                "src": "/static/1281143d695c0e20a9b10a095bb00363/4b190/glasstint-04.jpg",
                "srcSet": ["/static/1281143d695c0e20a9b10a095bb00363/e07e9/glasstint-04.jpg 200w", "/static/1281143d695c0e20a9b10a095bb00363/066f9/glasstint-04.jpg 400w", "/static/1281143d695c0e20a9b10a095bb00363/4b190/glasstint-04.jpg 800w", "/static/1281143d695c0e20a9b10a095bb00363/bcec7/glasstint-04.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>
    <div className="row">
      <div className="col-lg-3 offset-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/6b6fcd5c59220efb46a535866f8d2ba9/bcec7/glasstint-06.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAetc0qDUCA//xAAYEAADAQEAAAAAAAAAAAAAAAAAEBEBIf/aAAgBAQABBQJ9tK5h/8QAFhEAAwAAAAAAAAAAAAAAAAAAABAR/9oACAEDAQE/AXT/xAAUEQEAAAAAAAAAAAAAAAAAAAAg/9oACAECAQE/AR//xAAWEAEBAQAAAAAAAAAAAAAAAAAgMQD/2gAIAQEABj8CEE3/xAAcEAEAAgIDAQAAAAAAAAAAAAABABEQITFBUYH/2gAIAQEAAT8hrfOVOhZLepTtDK7aH5P/2gAMAwEAAgADAAAAEIvXAf/EABgRAAIDAAAAAAAAAAAAAAAAAAARARBR/9oACAEDAQE/EBTTaf/EABYRAAMAAAAAAAAAAAAAAAAAAAAQEf/aAAgBAgEBPxBw/8QAGxAAAwEBAAMAAAAAAAAAAAAAAAERIVFBkaH/2gAIAQEAAT8QUU2a4WpxaJYfQZBMW+5E8YcbVJt8kUkULp3WP//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Glasstint",
                "title": "Glasstint",
                "src": "/static/6b6fcd5c59220efb46a535866f8d2ba9/4b190/glasstint-06.jpg",
                "srcSet": ["/static/6b6fcd5c59220efb46a535866f8d2ba9/e07e9/glasstint-06.jpg 200w", "/static/6b6fcd5c59220efb46a535866f8d2ba9/066f9/glasstint-06.jpg 400w", "/static/6b6fcd5c59220efb46a535866f8d2ba9/4b190/glasstint-06.jpg 800w", "/static/6b6fcd5c59220efb46a535866f8d2ba9/bcec7/glasstint-06.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/25cfe3d584f1d647e6c7e6f839fde06b/bcec7/glasstint-07.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDBgQF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAP/aAAwDAQACEAMQAAAB9evOzLXINPMAP//EAB0QAAEDBQEAAAAAAAAAAAAAAAABAxMCBBARIkH/2gAIAQEAAQUCHE4qXSxXBG8QPnuP/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPwEf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPwEf/8QAGxAAAgEFAAAAAAAAAAAAAAAAADICAQMQIHH/2gAIAQEABj8CK8wtwSYktP/EABwQAAMAAgMBAAAAAAAAAAAAAAABESExEEHRkf/aAAgBAQABPyGO9l2rdGPYqZ+jG9evo2bOcv/aAAwDAQACAAMAAAAQYMgA/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAwEBPxAf/8QAFBEBAAAAAAAAAAAAAAAAAAAAIP/aAAgBAgEBPxAf/8QAIBABAQABAQkAAAAAAAAAAAAAAREAgRAhMUFRYXHB4f/aAAgBAQABPxBrhHhyToQHTKW4YM0yFUcz6Y9OP1XhSre5feIXt//Z')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Glasstint",
                "title": "Glasstint",
                "src": "/static/25cfe3d584f1d647e6c7e6f839fde06b/4b190/glasstint-07.jpg",
                "srcSet": ["/static/25cfe3d584f1d647e6c7e6f839fde06b/e07e9/glasstint-07.jpg 200w", "/static/25cfe3d584f1d647e6c7e6f839fde06b/066f9/glasstint-07.jpg 400w", "/static/25cfe3d584f1d647e6c7e6f839fde06b/4b190/glasstint-07.jpg 800w", "/static/25cfe3d584f1d647e6c7e6f839fde06b/bcec7/glasstint-07.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
      <div className="col-lg-3">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "800px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/0a9f754096670e0fa57099e326b814fa/bcec7/glasstint-08.jpg",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "100%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAUABQDASIAAhEBAxEB/8QAGAABAAMBAAAAAAAAAAAAAAAAAAIDBQb/xAAUAQEAAAAAAAAAAAAAAAAAAAAA/9oADAMBAAIQAxAAAAG7QwqTpURIAH//xAAaEAADAQADAAAAAAAAAAAAAAABAgMQBBEx/9oACAEBAAEFAjegbsZyWolZJSrjzf/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8BH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8BH//EABsQAQACAgMAAAAAAAAAAAAAAAEAMRFREBIg/9oACAEBAAY/Ak6Fyzh01Ml7hnx//8QAHBABAAICAwEAAAAAAAAAAAAAAQARIVEQIEGB/9oACAEBAAE/IThSUFYI1k0PF8/J5AxYa1UKErQy76f/2gAMAwEAAgADAAAAEFDAAP/EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQMBAT8QH//EABQRAQAAAAAAAAAAAAAAAAAAACD/2gAIAQIBAT8QH//EAB4QAQEAAQUAAwAAAAAAAAAAAAERABAhQVFhMcHR/9oACAEBAAE/EEq2NDawXDBSfgOhTNO4Po5BpDgk9/Mhxw7E3cQSJT3OJp//2Q==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Glasstint",
                "title": "Glasstint",
                "src": "/static/0a9f754096670e0fa57099e326b814fa/4b190/glasstint-08.jpg",
                "srcSet": ["/static/0a9f754096670e0fa57099e326b814fa/e07e9/glasstint-08.jpg 200w", "/static/0a9f754096670e0fa57099e326b814fa/066f9/glasstint-08.jpg 400w", "/static/0a9f754096670e0fa57099e326b814fa/4b190/glasstint-08.jpg 800w", "/static/0a9f754096670e0fa57099e326b814fa/bcec7/glasstint-08.jpg 1188w"],
                "sizes": "(max-width: 800px) 100vw, 800px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
      </div>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      